<template>
<el-dialog v-model="visible" title="修改密码" :before-close="close" custom-class="change-psw-dialog">
  <div class="cpd-container" v-loading="loading">
    <div class="cpdc-item">
      <div class="cpdci-title">原密码</div>
      <el-input placeholder="请输入原始密码" v-model.trim="form.oldpsw"></el-input>
      <div class="cpdci-hint" v-if="oldHintShow">原密码输入错误</div>
    </div>
    <div class="cpdc-item">
      <div class="cpdci-title">新密码</div>
      <el-input placeholder="请输入新密码" v-model.trim="form.newpsw"></el-input>
      <div class="cpdci-hint" v-if="newHintShow">6-18位，数字与字母结合，不可含有特殊字符</div>
    </div>
    <div class="cpdc-item">
      <div class="cpdci-title">再次输入新密码</div>
      <el-input placeholder="请再次输入新密码" v-model.trim="form.confirmPsw"></el-input>
      <div class="cpdci-hint" v-if="confirmHintShow">两次密码输入不一致</div>
    </div>
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button type="info" @click="close">取 消</el-button>
      <el-button type="primary" :disabled="btnDisabled" @click="confirm">确 定</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 修改密码 dialog
import { useStore } from "vuex";
import { changePSW } from "api/apis.js";
import { ElMessage } from 'element-plus';
import { reactive, toRefs, computed } from "vue";
export default {
  setup(prop, ctx) {
    const store = useStore();
    const state = reactive({
      visible: true,
      userInfo: computed(() => store.getters.userInfo),
      form: {
        oldpsw: "",
        newpsw: "",
        confirmPsw: ""
      },
      loading: false,
      oldHintShow: computed(() => {
        if ((state.form.oldpsw || "") != "") {
          if (state.userInfo.txt_login_pwd != state.form.oldpsw) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }),
      newHintShow: computed(() => {
        if ((state.form.newpsw || "") != "") {
          //  判断 字符长度 和 格式
          // 大小写、数字、特殊字符 6-20 包含两项
          let reg = new RegExp("^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$");
          if (reg.test(state.form.newpsw)) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }),
      confirmHintShow: computed(() => {
        if ((state.form.confirmPsw || "") != "") {
          if (state.form.newpsw == state.form.confirmPsw) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }),
      btnDisabled: computed(() => {
        // 不存在空值
        if (Object.values(state.form).findIndex(t => (t || "") == "") == -1) {
          // 不存在 不符合条件的数据
          if ([state.oldHintShow, state.newHintShow, state.confirmHintShow].findIndex(t => t == true) == -1) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      })
    })

    const close = () => {
      ctx.emit("close");
    }

    // 提交
    const confirm = () => {
      state.loading = true;
      changePSW({
        oldPassword: state.form.oldpsw,
        newPassword: state.form.newpsw
      }).then(response => {
        if (response.code == 200) {
          ElMessage.success("修改成功，请重新登录");
          setTimeout(() => {
            sessionStorage.clear();
            state.loading = false;
            location.reload();
          }, 1000)
        } else {
          ElMessage.error(response.message);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    return {
      ...toRefs(state),
      close,
      confirm
    }
  }
}
</script>

<style lang="scss">
.change-psw-dialog {
  width: 500px !important;
  height: calc(100% - 50vh) !important;

  .cpd-container {
    height: 100%;
    box-sizing: border-box;
    padding: 10px 26px;

    .cpdc-item {
      margin-bottom: 18px;

      .cpdci-title {
        font-size: 16px;
        margin-bottom: 6px;
      }

      .el-input {
        .el-input__wrapper {
          border-radius: 18px;
        }
      }

      .cpdci-hint {
        margin-top: 4px;
        font-size: 12px;
        color: rgb(206, 26, 26);
      }
    }
  }

  .dialog-footer {
    .el-button {
      font-weight: bold;
      border-radius: 16px;
    }
  }
}
</style>
