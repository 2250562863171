<template>
<div class="app-wrapper">
  <!-- 判断是否登录 -->
  <template v-if="isLogin">
    <layout>
      <router-view />
    </layout>
  </template>
  <!-- 未登录显示登录页面 -->
  <template v-else>
    <login />
  </template>
</div>
</template>

<script>
import Layout from "layout";
import * as echarts from 'echarts';
import { useRouter } from "vue-router";
import Login from "views/login/index.vue";
import { reactive, toRefs, watch, provide } from 'vue';
export default {
  components: {
    Login,
    Layout
  },
  setup() {
    const router = useRouter();
    // 全局注入echarts
    provide("echart", echarts)
    const state = reactive({
      isLogin: false
    });

    // 监听当前路由
    watch(() => router.currentRoute.value, (newValue) => {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      // 如果是普通页面
      if (newValue.path != "/login") {
        // 判断是否是登陆状态
        if (userInfo) {
          state.isLogin = true;
        } else {
          state.isLogin = false;
        }
      } else {
        state.isLogin = false;
      }
    }, { immediate: true })

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-wrapper {
  height: 100%;

  #page {
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      display: block;
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: rgba(14, 22, 37, 0.5);
    }
  }
}
</style>
