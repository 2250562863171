import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ 'views/login/index.vue'),
    meta: { title: "登录" }
  },
  {
    path: '/',
    redirect: "/report"
  },
  {
    path: "/report",
    name: "ReportMa",
    component: () => import(/* webpackChunkName: "report" */ 'views/report/index.vue'),
    meta: { title: "检测报告管理" }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 路由导航首页
router.beforeEach((to, from, next) => {
  // 跳过开发环境
  // if (process.env.NODE_ENV == "development") {
  //   next();
  // } else {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    // 如果当前不是登录页
    if (to.path != "/login") {
      // 没有用户信息，跳转至登录页
      if (!userInfo) {
        next("/login");
      } else {
        // 有 用户信息  正常跳转页面
        next();
      }
    } else {
      // 是登录页 继续
      next();
    }
  // }
})

window.router = router;

export default router
