import request from './request';

// 登录
export function goLogin(data) {
  return request({
		// url: "/clinicend/clinic/clinicLogin",
    url: "/apis/pc/api/v1/auth/login",
		method: "post",
		data
  })
}

// 修改密码
export function changePSW(data) {
  return request({
		// url: "/clinicend/clinic/changePwd",
    url: "/apis/pc/api/v1/auth/modify_password",
		method: "post",
		data
  })
}

// 获取设备类型
export function getDeviceTypeList(data) {
  return request({
		url: "/apis/pc/api/v1/order/get_device_type_list",
		method: "post",
		data
  })
}

// 获取订单列表
export function getClinicOrders(data) {
  return request({
		// url: "/getOrderList",
		url: "/apis/pc/api/v1/order/get_order_list",
		method: "post",
		data
  })
}

// 获取详情
export function getOrderDetailById(data) {
  return request({
		// url: "/getOrderDetail",
		url: "/apis/pc/api/v1/order/get_order_detail",
		method: "post",
		data
  })
}

// 保存病历信息
export function updateCase(data) {
  return request({
		// url: "/saveCase",
    url: "/apis/pc/api/v1/order/update_order_doctor_info",
		method: "post",
		data
  })
}

// 获取病历信息 接口更新后废弃
// export function getCase(data) {
//   return request({
// 		url: "/getOrderSuggestion",
// 		method: "post",
// 		data
//   })
// }
