<template>
<div class="login-login">
  <div class="il-top row-center-start">
    <div></div>
    <div></div>
    <div></div>
  </div>
  <div class="il-middle row-center-center">
    <el-carousel indicator-position="outside" arrow="never">
      <el-carousel-item>
        <img src="../../assets/imgs/login_back_1.svg">
        <div class="ilm-carousel-item-title column-center-center">
          <span>检测报告查看</span>
          <span>查看全部已完成的检测记录</span>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <img src="../../assets/imgs/login_back_2.svg">
        <div class="ilm-carousel-item-title column-center-center">
          <span>患者病历维护</span>
          <span>添加医嘱建议、病人主诉信息</span>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <img src="../../assets/imgs/login_back_3.svg">
        <div class="ilm-carousel-item-title column-center-center">
          <span>打印配置及打印</span>
          <span>支持A4/A5打印</span>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="ilm-form-part column-center-center">
      <div class="ilmfp-title">
        <span>欢迎登录</span>
        <span> / 联心诊所数字化工作站</span>
      </div>
      <div class="ilmfp-form">
        <div class="ilmfpf-title">手机号</div>
        <el-form :model="form" :rules="rules" ref="ruleFormRef">
          <el-form-item prop="mobile">
            <el-input placeholder="请输入您的手机号" v-model="form.mobile" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="form.password" type="password" show-password placeholder="请输入您的登录密码"></el-input>
          </el-form-item>
          <div class="ilmfpf-forgetpw" @click="forgetpw">忘记密码</div>
        </el-form>
        <el-button type="primary" color="#00b0ff" size="large" @click="validForm">登录</el-button>
      </div>
      <div class="ilmfp-deal row-center-start">
        <el-tooltip :visible="deal.visible" class="box-item" effect="dark" content="请先阅读《服务协议》与《隐私政策》" placement="top-start">
          <el-checkbox v-model="deal.checked" size="large" />
        </el-tooltip>
        <div class="ilmfpd-text">我已阅读并同意<a>服务协议</a>和<a>隐私政策</a></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
// 登录页
import { goLogin } from "api/apis.js";
import { useRouter } from "vue-router";
import { reactive, toRefs, ref } from "vue";
import { FormInstance, ElMessageBox, ElMessage } from 'element-plus';
export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      ruleFormRef: ref(FormInstance),
      form: {
        password: "",
        mobile: "",
      },
      deal: {
        visible: false,
        checked: false
      },
      rules: {
        password: [{ required: true, message: "请输入登录密码", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入登录手机号", trigger: "blur" },
          { required: true, message: "手机号格式不正确", pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, trigger: "blur" },
        ],
      },
    });

    // 表单校验
    const validForm = () => {
      // 手机号 + 密码 必填校验
      state.ruleFormRef.validate(valid => {
        // 校验通过
        if (valid) {
          // 判断是否勾选了 协议+政策
          if (state.deal.checked) {
            // 勾选了，取消tooltip提示，调用登录接口
            state.deal.visible = false;
            goLogin({
              mobile: state.form.mobile,
              password: state.form.password,
              loginMode: 1  // 手机号密码登录
            }).then(response => {
              if (response.code == 200) {
                response.data.payloads.txt_login_pwd = state.form.password;
                response.data.payloads.token = response.data.token;
                sessionStorage.setItem("userInfo", JSON.stringify(response.data.payloads));
                router.replace({ path: "/" });
              } else {
                ElMessage.error(response.message);
              }
            }).catch(e => {
              console.error(e);
            })
          } else {
            // 没勾选，显示 tooltip 提示
            state.deal.visible = true;
            setTimeout(() => {
              state.deal.visible = false;
            }, 1500);
          }
        }
      })
    };

    // 找回密码 提示
    const forgetpw = () => {
      ElMessageBox.confirm("如忘记密码，请联系客服人员进行重置", "密码找回", {
        confirmButtonText: "我知道了",
        showCancelButton: false
      }).then(() => {}).catch(() => {});
    };

    return {
      ...toRefs(state),
      validForm,
      forgetpw
    }
  }
}
</script>

<style lang="scss" scoped>
.login-login {
  height: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
  background-color: #eeeeef;

  .il-top {

    div {
      width: 16px;
      height: 16px;
      border-radius: 16px;
      margin-right: 10px;
      background-color: #e6a23c;

      &:first-child {
        background-color: #f56c6c;
      }

      &:last-child {
        background-color: #67c23a;
      }
    }
  }

  .il-middle {
    height: calc(100% - 20px);

    .el-carousel {
      width: 300px;
      height: 450px;
      margin-right: 150px;

      /deep/ .el-carousel__container {
        height: calc(100% - 30px);
      }

      .el-carousel__item {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          height: 250px;
          margin-bottom: 55px;
        }

        .ilm-carousel-item-title {
          span {
            letter-spacing: 2px;

            &:first-child {
              font-weight: bold;
              font-size: 26px;
              margin-bottom: 6px;
            }
          }
        }
      }
    }

    .ilm-form-part {
      background-color: #ffffff;
      border-radius: 10px;
      padding: 65px 60px;
      box-shadow: 0 0 10px 2px rgba($color: #b1b0b0, $alpha: 1.0);

      .ilmfp-title {
        margin-bottom: 50px;

        span {
          letter-spacing: 2px;

          &:first-child {
            font-size: 26px;
            font-weight: bold;
          }

          &:last-child {
            color: #8a8c8a;
          }
        }
      }

      .ilmfp-form {
        .ilmfpf-title {
          width: fit-content;
          color: #00b0ff;
          font-weight: bold;
          letter-spacing: 2px;
          padding-bottom: 4px;
          margin-bottom: 15px;
          border-bottom: 2px solid #00b0ff;
        }

        .el-form {
          width: 350px;

          .ilmfpf-forgetpw {
            width: 100%;
            text-align: right;
            color: #42b5ff;
            cursor: pointer;
            font-size: 14px;
          }
        }

        .el-button {
          width: 100%;
          color: #ffffff;
          margin-top: 60px;
          letter-spacing: 4px;
          font-weight: bold;
        }
      }

      .ilmfp-deal {
        width: 100%;
        margin-top: 35px;

        .ilmfpd-text {
          font-size: 14px;
          letter-spacing: 4px;
          margin-left: 6px;

          a {
            cursor: pointer;
            color: #52c1ff;
          }
        }
      }
    }
  }
}
</style>
