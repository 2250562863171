// import Cookies from "js-cookie";

const getters = {
  userInfo: () => {
    if (process.env.NODE_ENV == "development") {
      return {
        clinicConfigId: 17,
        clinicAgentId: 253,
        txtRealName: "安安",
        txtMobile: "13716053694",
        txt_login_pwd: "lx17695912203"
      }
    } else {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      return userInfo;
    }
  },
  // 生成随机字符串
  UUID() { return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, function () { return (Math.random() * 16 | 0).toString(16); }); },
};

export default getters;