import { createStore } from 'vuex';
import app from "./modules/app"; 
import getters from "./getters";


export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app
  },
  getters
})
