
// 项目基本
const state = {
  orderItem: {},
  userInfo: {}
}

const mutations = {
  SET_ORDER_ITEM: (state, param) => {
    state.orderItem = param;
  }
}

export default {
  state,
  mutations
}