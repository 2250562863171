<template>
<el-container class="layout-page-container">
  <el-header class="lpc-header row-center-between">
    <div class="lpch-left row-center-start">
      <img src="../assets/imgs/menu.png">
      <div class="lpchl-title">联心诊所数字化工作站</div>
    </div>
    <div class="lpch-right row-center-start">
      <div @click="dialogShow=true" class="lpchr-cpw row-center-center">
        <img src="../assets/imgs/cpw.png">
        <span>修改密码</span>
      </div>
      <div class="lpcyr-user">
        <span>{{ userInfo.txtMobile }}</span>
        <span>{{ userInfo.txtRealName }}</span>
      </div>
      <el-tooltip class="box-item" effect="dark" content="退出登录" placement="bottom">
        <img src="../assets/imgs/exit.png" class="lpcyr-exit" @click="logOut">
      </el-tooltip>
      <change-psw v-if="dialogShow" @close="dialogShow=false" />
    </div>
  </el-header>
  <el-main>
    <slot></slot>
  </el-main>
</el-container>
</template>

<script>
// 系统整体布局
import { useStore } from "vuex";
import { ChangePsw } from "components";
import { ElMessageBox } from 'element-plus';
import { reactive, toRefs, computed } from 'vue';
export default {
  components: {
    ChangePsw
  },
  setup() {
    const store = useStore();
    const state = reactive({
      dialogShow: false,
      userInfo: computed(() => store.getters.userInfo || {})
    });

    const logOut = () => {
      ElMessageBox.confirm("确定要退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        sessionStorage.clear();
        window.location.reload();
      }).catch(() => {});
    };

    return {
      ...toRefs(state),
      logOut
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-page-container {
  height: 100%;
  width: 100%;
  background-color: #f4f9fa;

  .lpc-header {
    margin-bottom: 14px;
    background-color: #ffffff;
    border-bottom: 1px solid #a8abac;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .lpch-left {
      font-size: 20px;

      .lpchl-title {
        margin-left: 18px;
        padding-right: 18px;
        border-right: 1px solid #c2bfbf;
      }
    }

    .lpch-right {
      font-size: 14px;

      .lpchr-cpw {
        padding: 4px 10px;
        border-radius: 14px;
        cursor: pointer;
        border: 1px solid #a8abac;

        span {
          margin-left: 8px;
        }
      }

      .lpcyr-user {
        margin-left: 24px;
        margin-right: 20px;

        span {
          &:first-child {
            font-weight: bold;
            margin-right: 16px;
          }

          &:last-child {
            padding: 6px 12px;
            border-radius: 12px;
            background-color: #e6e6e6;
          }
        }
      }

      .lpcyr-exit {
        cursor: pointer;
      }
    }
  }

  .el-main {
    background-color: #ffffff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top: 1px solid #e7eced;
  }
}
</style>
