import axios from "axios";


axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000
});

// request 拦截器
service.interceptors.request.use(config => {
  config.headers['LX-Client-Type'] = "1";
  // 所有请求中携带token
  if (!config.url.includes("login")) {
    let userInfo = sessionStorage.getItem("userInfo");
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      config.headers['Authorization'] = userInfo.token;
      return config;
    } else {
      sessionStorage.clear();
      location.replace("/login");
      return false;
    }
  } else {
    return config;
  }
}, err => {
  console.error(err);
  Promise.reject(err);
});

// 响应拦截器
service.interceptors.response.use(res => {
  if (res.data.code == 40001) {
    sessionStorage.clear();
    location.replace("/login");
  } else {
    return res.data;
  }
}, err => {
  console.error(err);
  Promise.reject(err);
});

export default service;